import styles from "../../styles/PageBlocks.module.scss";
import React from "react";

export default props => {
  return (
    <div
      className = {
      ( props.compact ) ? styles[ "ticketSectionCompact" ]
        : ( props.vertical ) ? styles[ "ticketSectionVertical" ] : styles[ "ticketSection" ] }
      index = { props.index }
    >
      { props.children }
    </div>
  );
}