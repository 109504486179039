import SimpleTextArea from "../common/SimpleTextArea.jsx";
import TicketSection from "../common/TicketSection.jsx";
import React from "react";

export default props => {
  return (
    <TicketSection text = { props.data.name } compact = { props.compact } >
      <SimpleTextArea
        name = { props.data.name }
        value = { props.data.value }
        update = { props.data.update }
      />
    </TicketSection>
  );
}