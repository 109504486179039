import TicketBlocks from "./TicketBlocks.jsx";
import Controls from "./Controls.jsx";
import { useParams } from "react-router-dom";
import React, {useContext, useState} from "react";

import Settings from "../../data/global/Settings.js";
import Users from "../../data/global/Users.js";
import Domains from "../../data/global/Domains.js";
import Projects from "../../data/global/Projects.js";
import {setValueToFunction} from "../../data/global/commonFunctions.js";

const TicketWriterModule = () => {
  const params = useParams();
  const settings = useContext( Settings );
  const domains = useContext( Domains );
  const users = useContext( Users );
  const projects = useContext( Projects );

  const translateGivenValueToStandard = ( list, givenValue, generalValue ) => {
    let valueToReturn;
    list.forEach( itemsList => { itemsList.id.forEach( item =>
        ( item === givenValue ) ? valueToReturn = itemsList.id[0] : null )});
    return valueToReturn ? valueToReturn : generalValue;
  }

  // converting data we got from user
  let domain =  translateGivenValueToStandard( domains, params.domainId,  settings.defaultValues.domain.id );
  let user =    translateGivenValueToStandard( users, params.userId,    settings.defaultValues.user.id );
  let project = translateGivenValueToStandard( projects, params.projectId, settings.defaultValues.project.id );

  // console.log ( settings.defaultValues.domain );
  // console.log ( user );
  // console.log ( domain );
  // console.log ( project );
  let defaultActions;
  switch( domain ) {
    case "nav":
      defaultActions = settings.defaultValues.domain.nav.actionsText;
      break;
    default:
      defaultActions = settings.defaultValues.ticketBlocks._default;
  }

  const initialValues = {
    firstCategory      : settings.defaultValues.global.emptyValue,
    secondCategory     : settings.defaultValues.global.emptyValue,
    thirdCategory      : settings.defaultValues.global.emptyValue,
    enteredDescription : settings.defaultValues.global.emptyValue,

    ticketName         : settings.defaultValues.ticketBlocks.ticketName,
    folderName         : settings.defaultValues.global.emptyDescription,
    screenName         : settings.defaultValues.global.emptyDescription,
    videoName          : settings.defaultValues.global.emptyDescription,
    tracesName         : settings.defaultValues.global.emptyDescription,

    actions            : defaultActions,
    observations       : settings.defaultValues.ticketBlocks._default,
    expectations       : settings.defaultValues.ticketBlocks._default,

    configuration      : settings.defaultValues.ticketBlocks._defaultAlt,
    preconditions      : settings.defaultValues.ticketBlocks._default,
    timestamps         : settings.defaultValues.ticketBlocks._default,
    healing            : settings.defaultValues.ticketBlocks._default,
    comments           : settings.defaultValues.ticketBlocks._default,
    attachments        : settings.defaultValues.ticketBlocks._default,
    contacts           : settings.defaultValues.ticketBlocks.contacts,

    kpmStuff           : settings.defaultValues.ticketBlocks._default
  }

  // components of the ticket
  const [ ticketName, setTicketName ] = useState( initialValues.ticketName );
  const [ folderName, setFolderName ] = useState( initialValues.folderName );

  const [ videoName, setVideoName ] = useState( initialValues.videoName );
  const [ screenName, setScreenName ] = useState( initialValues.screenName );
  const [ tracesName, setTracesName ] = useState( initialValues.tracesName );

  const [ configuration, setConfiguration ] = useState( initialValues.configuration  );
  const [ preconditions, setPreconditions ] = useState( initialValues.preconditions );

  const [ actions, setActions ] = useState( initialValues.actions );
  const [ observations, setObservations ] = useState( initialValues.observations );
  const [ expectations, setExpectations ] = useState( initialValues.expectations );

  const [ timestamps, setTimestamps ] = useState( initialValues.timestamps );
  const [ healing, setHealing ] = useState( initialValues.healing );
  const [ comments, setComments ] = useState( initialValues.comments );
  const [ attachments, setAttachments ] = useState( initialValues.attachments );
  const [ contacts, setContacts ] = useState( initialValues.contacts );

  const [ kpmStuff, setKpmStuff ] = useState( initialValues.kpmStuff );

  const TicketBlocksListFull = [
    {
      name : "ticketName",
      value : ticketName,
      update : setTicketName,
      default : initialValues.ticketName,
      copyIfEmpty : true,
      firstCategory : initialValues.firstCategory,
      secondCategory : initialValues.secondCategory,
      thirdCategory : initialValues.thirdCategory,
      enteredDescription : initialValues.enteredDescription,
    },
    {
      name : "folderName",
      value : folderName,
      update : setFolderName,
      default : initialValues.folderName
    },
    {
      name : "videoName",
      value : videoName,
      update : setVideoName,
      default : initialValues.videoName
    },
    {
      name : "screenName",
      value : screenName,
      update : setScreenName,
      default : initialValues.screenName
    },
    {
      name : "tracesName",
      value : tracesName,
      update : setTracesName,
      default : initialValues.tracesName
    },
    {
      name : "configuration",
      value : configuration,
      update : setConfiguration,
      default : initialValues.configuration,
      copyIfEmpty : true
    },
    {
      name : "preconditions",
      value : preconditions,
      update : setPreconditions,
      default : initialValues.preconditions,
      copyIfEmpty : false
    },
    {
      name : "actions",
      value : actions,
      update : setActions,
      default : initialValues.actions,
      copyIfEmpty : true
    },
    {
      name : "observations",
      value : observations,
      update : setObservations,
      default : initialValues.observations,
      copyIfEmpty : true
    },
    {
      name : "expectations",
      value : expectations,
      update : setExpectations,
      default : initialValues.expectations,
      copyIfEmpty : true
    },
    {
      name : "attachments",
      value : attachments,
      update : setAttachments,
      default : initialValues.attachments,
      copyIfEmpty : true
    },
    {
      name : "healing",
      value : healing,
      update : setHealing,
      default : initialValues.healing,
      copyIfEmpty : false
    },
    {
      name : "comments",
      value : comments,
      update : setComments,
      default : initialValues.comments,
      copyIfEmpty : false
    },
    {
      name : "contacts",
      value : contacts,
      update : setContacts,
      default : initialValues.contacts,
      copyIfEmpty : true
    },
    {
      name : "kpmStuff",
      value : kpmStuff,
      update : setKpmStuff,
      default : initialValues.kpmStuff,
      copyIfEmpty : true
    },
    {
      name : "timestamps",
      value : timestamps,
      update : setTimestamps,
      default : initialValues.timestamps,
      copyIfEmpty : false
    },
  ];

  const TicketBlocksToUpdateForSimilarTicket = [
    {
      elementsToCopy : [
        "ticketName",
        "preconditions",
        "actions",
        "observations",
        "expectations",
        "timestamps",
        "comments",
        "attachments",
        "healing",
      ]
    },
  ];

  const TicketBlocksListForNextTicket = [
    {
      elementsToCopy : [
        "configuration",
        "preconditions",
        "actions",
        "observations",
        "expectations",
        "timestamps",
        "comments",
        "attachments",
        "healing",
        "contacts",
      ]
    },
  ];

  const TicketBlocksListForTextCopy = [
    {
      elementsToCopy : [
        "configuration",
        "preconditions",
        "actions",
        "observations",
        "expectations",
        "attachments",
        "timestamps",
        "comments",
        "healing",
        "contacts",
      ]
    },
  ];

  const TicketBlocksListForCodeCopy = [
    {
      elementsToCopy : [
        "configuration",
        "preconditions",
        // "actions",
        // "observations",
        // "expectations",
        // "attachments",
        // "healing",
        // "contacts",
      ]
    },
  ];

  const listOfListsToCopyDataIn = [
    TicketBlocksListForNextTicket,
    TicketBlocksToUpdateForSimilarTicket,
    TicketBlocksListForTextCopy,
    TicketBlocksListForCodeCopy
  ];


  // copying data into the lists from TicketBlocksListFull
  let hasFound = false;
  listOfListsToCopyDataIn.forEach( list => {
    list[0].elementsToCopy.forEach( itemNeeded => {
      TicketBlocksListFull.every( listItem => {
        hasFound = listItem.name === itemNeeded;
        if ( hasFound ) list.push( listItem );
        return !hasFound;
      });
    });
    list.shift();
  });

  let dataToUpdate;
  let clearTicketNameWithItsParts = () => setValueToFunction( dataToUpdate );
  let setTicketNameWithItsParts = data => dataToUpdate = data;

  return (
    <>
      <TicketBlocks
        ticketBlocksListFull = { TicketBlocksListFull }
        domain = { domain }
        user = { user }
        project = { project }
        setTicketNameWithItsParts = { setTicketNameWithItsParts }
      />
      <Controls
        ticketBlocksListFull = { TicketBlocksListFull }
        ticketBlocksListShort = { TicketBlocksListForTextCopy }
        ticketBlocksListForSimilarTicket = { TicketBlocksToUpdateForSimilarTicket }
        clearTicketNameWithItsParts = { clearTicketNameWithItsParts }

        ticketBlocksListForTextCopy = { TicketBlocksListForTextCopy }
        ticketBlocksListForCodeCopy = { TicketBlocksListForCodeCopy }
      />
    </>
  );
}

export default TicketWriterModule;