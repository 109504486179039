import {createContext} from "react";

const Settings = createContext({
  defaultValues : {
    global : {
      space : " : ",
      lineBreak : "\n",
      doubleLineBreak : "\n\n",
      // doubleLineBreak : "\n\r\n\r",
      // lineBreak : "\n\r",
      tabIndexInit : 1,
      tabIndexHigh : 5,
      tabIndexMid : 10,
      tabIndexLow : 99,
      emptyValue : "",
      emptyValueAlt : " ",
      emptyDescription : "...",
    },
    ticketBlocks : {
      _default : "none",
      _defaultAlt : " ",
      _defaultArray : [],
      ticketName : "click on text to copy - for Ticket name, folder, screenshot, video, traces",
      shortDescription : "Short description",
      contacts : "Name:\nMail:",
    },
    html : {
      input : {
        maxLength : 100,
        minLength : 5
      },
      textarea : {
        cols : 80,
        defaultValue : "none",
      },
      tabindex : {
        ticketBlocks : 10,
        controls : 900,
      }
    },
    domain : {
      general : {
        id : "general",
      },
      nav : {
        id : "nav",
        actionsText : "1. Start native Navi app and route to "
      }
    },
    project : {
      id : "general",
      default : "hcp",
    },
    user : {
      id : "general",
      name : "Enter your name here",
      mail : "your.mail@here.de",
    },
    versions : {
      mib : {
        sw : "E4524",
        conmod : "XXX",
        iphone : 13,
        navi : "P1300",
        ios : "16.4.1",
        isMaster : false,
        android : "Samsung Galaxy S21 FE",
        androidOS : 13,
        lang : "German",
        testedOn : "testbench",
        naviTestedOn : "SEU800",
        connected : "wireless",
        summary1 : "none",
        summary2 : "none"
      },
      hcp : {
        sw : "P552",
        conmod : "0014",
        hcp5 : "X104",
        navi : "XXXXXXX_1120_EU",
        iphone : 13,
        ios : "16.4.1",
        isMaster : false,
        android : "Samsung Galaxy S21 FE",
        androidOS : 13,
        lang : "German",
        testedOn : "testbench",
        naviTestedOn : "testbench",
        connected : "wireless",
        summary1 : "none",
        summary2 : "none"
      },
      general : {

      },
      default : {
        project : "hcp",
        sw : "default",
      }
    },
    contacts : {
      organization : "",
      phone : "",
    },
    labels : {
      general : {
        testedOn : "Tested on",
        swVersion : "SW",
        hcp3 : "HCP3",
        hcp5 : "HCP5",
        conmod : "Conmod",
        navi : "Navigation",
        other : "Other",
        lang : "Language",
      },
      navi : {
        navigation : "Navigation",
      },
      smartIntegration : {
        iphone : "iPhone #1",
        iphone2 : "iPhone #2",
        ios : "#1 iOS",
        ios2 : "#2 iOS",
        isMaster : "started APC",
        android : "Phone",
        connected : "Connection",
      },
      timestamps : {
        trigger : "Trigger",
        triggerDate : "Date",
        triggerTime : "Time",
        trigger1 : "Trigger #",
        trigger2 : "Trigger #",
        trigger1date : "Date",
        trigger2date : "Date Tr. 2",
        trigger1time : "Time Tr. 1",
        trigger2time : "Time Tr. 2",
      },
      contacts : {
        name : "Name",
        organization : "Firma",
        email : "E-Mail",
        phone : "Tel."
      },
    }
  },
  nameGenerator : {
    maxOverallKPMTitleLength : 50,
    spaceBtwCatsAndTitleWidth : 1,
    catInputValue : "Category",
    catInputLength : "15",
    titleInputDefaultText: "Short description here",
    filenamesToGenerate : [
      {
        name : "title",
        prefix : "",
        value : "ticketTitle",
        updateFunction : "setTicketTitle",
      },
      {
        name : "internalID",
      },
      { },
    ],
  },
});

export default Settings;