import styles from "../../styles/Controls.module.scss";
import CopyButtonsBlock from "./controls/CopyButtonsBlock.jsx";
import ClearButtonsBlock from "./controls/ClearButtonsBlock.jsx";
import {useContext} from "react";
import AppSettings from "../../data/global/Settings.js";

const Controls = props => {
  const settings = useContext( AppSettings );
  const tabIndexDefault = settings.defaultValues.html.tabindex.controls;

  return (
    <section className = { styles.controls } >
      <CopyButtonsBlock
        ticketBlocksListFull = { props.ticketBlocksListFull }
        ticketBlocksListShort = { props.ticketBlocksListShort }
        index = { tabIndexDefault + 10 }
      />

      <ClearButtonsBlock
        ticketBlocksListFull = { props.ticketBlocksListFull }
        ticketBlocksListShort = { props.ticketBlocksListShort }
        ticketBlocksListForNextTicket = { props.ticketBlocksListForSimilarTicket }
        clearTicketNameWithItsParts = { props.clearTicketNameWithItsParts }
        index = { tabIndexDefault + 20 }
      />
    </section>
  );
}

export default Controls;