import React from "react";
import AtomicHeader from "./AtomicHeader.jsx";
import Block from "../pageBlocks/Block.jsx";

const TicketSection = props => {
  return (
    <Block
      compact = { props.compact }
      vertical = { props.vertical }
      index   = { props.index }
    >
      { ( props.text ) ? <AtomicHeader text = { props.text } /> : null }
      { props.children }
    </Block>
  )
}

export default TicketSection;