import {useContext} from "react";
import AppSettings from "../../../data/global/Settings.js";

const ClearButtonsBlock = props => {

  const appSettings = useContext( AppSettings );
  const tabIndexMid = appSettings.defaultValues.global.tabIndexMid;

  const onClearHandler = ( list ) => {
    list.forEach( listItem => listItem.update( listItem.default ));
  }

  const onClickHandlerFull = event => {
    event.preventDefault();
    props.clearTicketNameWithItsParts();
    onClearHandler( props.ticketBlocksListFull );
  }

  const onClickHandlerShort = event => {
    event.preventDefault();
    props.clearTicketNameWithItsParts();
    onClearHandler( props.ticketBlocksListForNextTicket );
  }

  return (
    <>
      {/* Next Ticket */}
      <button
        onClick = { onClickHandlerShort }
        tabIndex = { props.index + 1 }
      >
        Ähnliches Ticket
      </button>

      {/* Reset to defaults */}
      <button
        onClick = { onClickHandlerFull }
        tabIndex = { props.index + 2 }
      >
        Full reset
      </button>
    </>
  );
}

export default ClearButtonsBlock;