import TicketSection from "../common/TicketSection.jsx";
import SimpleTextArea from "../common/SimpleTextArea.jsx";
import React from "react";

export default props => {
  return (
    <>
      {/* OBSERVATIONS */}
      <TicketSection text = { props.observations.name } compact = { props.oCompact } >
        <SimpleTextArea
          name   = { props.observations.name }
          value  = { props.observations.value }
          update = { props.observations.update }
          index  = { props.index + 1 }
        />
      </TicketSection>
    </>
  );
}