import TicketSection from "../common/TicketSection.jsx";
import SimpleTextArea from "../common/SimpleTextArea.jsx";
import React from "react";

export default props => {
  return (
    <>
      {/* ACTIONS */}
      <TicketSection text = { props.actions.name } compact = { props.aCompact } >
        <SimpleTextArea
          name = { props.actions.name }
          value = { props.actions.value }
          update = { props.actions.update }
          index  = { props.index }
        />
      </TicketSection>
    </>
  );
}