import SimpleCheckbox from "../../common/SimpleCheckbox.jsx";
import SimpleInput from "../../common/SimpleInput.jsx";
import React, {useContext, useEffect, useState} from "react";
import Settings from "../../../data/global/Settings.js";
import SimpleDateInput from "../../common/SimpleDateInput.jsx";

const Timestamp = props => {
  const settings = useContext( Settings );
  const labelTrigger = settings.defaultValues.labels.timestamps.trigger;
  const currentTimestampIsFirst = ( props.firstTimestamp );
  let disabled = ( props.firstTimestamp ) ? props.disabled : props.disabledSecond;

  return ( !props.visible ) ? null : (
    <>
      <SimpleCheckbox
        name =   { `timestampEnabler_${ currentTimestampIsFirst }` }
        update = { ( currentTimestampIsFirst ) ? props.setFirstCheckboxStatus : props.setSecondCheckboxStatus }
        index  = { props.index }
        defaultChecked = { ( currentTimestampIsFirst ) ? props.firstCheckboxStatus : props.secondCheckboxStatus }
        tiny
      />

      <SimpleDateInput
        name =   { "dateOne" }
        label =  { "Date" }
        value =  { props.values[0] }
        update = { props.setters[0] }
        index  = { props.index }
        disabled = { disabled }
        small
      />

      <SimpleInput
        name =   { "timeOne" }
        label =  { "Time" }
        value =  { props.values[1] }
        update = { props.setters[1] }
        index  = { props.index }
        type = { "Time" }
        disabled = { disabled }
        small
      />

      <SimpleInput
        name =   { "triggerNumber" }
        label =  { labelTrigger }
        value =  { props.values[2] }
        update = { props.setters[2] }
        index  = { props.index }
        disabled = { disabled }
        small
      />
    </>
  );
}

export default Timestamp;
