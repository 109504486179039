import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  useParams
} from "react-router-dom";

import Root from "./root.jsx";
import ErrorPage from "./errorpage.jsx";
import TicketWriterModule from "../components/sections/TicketWriterModule.jsx";

// import ErrorPage from "./error-page.jsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement : <ErrorPage/>,
    loader: async ({ params }) => {
      // switch ( params.domainId ) {
      // case "sm" : return redirect( "sch/cross/mib" );
      // case "sh" : return redirect( "sch/cross/hcp" );
      // case "gs" : return redirect( "sch/gs/hcp" );
      // case "k"  : return redirect( "sch/kbd/hcp" );
      // case "cr" : return redirect( "sch/cr/hcp" );

      // default : return null
      // }
      return null;
    },
    children: [
      {
        path: ":userId?/:domainId?/:projectId?",
        element: <TicketWriterModule/>,
        loader: ({ params }) => {
          params.userId;
          params.domainId;
          params.projectId;
          return null;
        },
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router = { router } />
  </React.StrictMode>,
)
