import {createContext} from "react";

const Users = createContext([
  {
    id : [ "general" ],
    name : "Your name & surname ( required )",
    organization : "What company are you working for?",
    mail : "What is your E-Mail? ( required )",
    phone : "Your phone number here"
  },
  {
    id : [ "sch", "s" ],
    name : "Alexander Schneider",
    mail : "extern.alexander.schneider1@porsche.de",
  },
  {
    id : [ "dimi", "d" ],
    name : "Dimi Welker",
    mail : "extern.dimitrij.welker@porsche.de",
  },
  {
    id : [ "ab" ],
    name : "Alexander Bogojevic",
    mail : "extern.alexander.bogojevic@porsche.de",
  },
  {
    id : [ "pm" ],
    name : "Philipp Mendrock",
    mail : "extern.philipp.mendrock@porsche.de",
  },
  {
    id : [ "kk" ],
    name : "Kevin Klein",
    mail : "extern.kevin.klein@porsche.de",
  },
  {
    id : [ "kas" ],
    name : "Kasim Keskinkaya",
    mail : "extern.kasim.keskinkaya@porsche.de",
  },
  {
    id : [ "md" ],
    name : "Marc Drevenka",
    mail : "extern.marc.drevenka@porsche.de",
  },
  {
    id : [ "ms" ],
    name : "Marcel Sachs",
    mail : "extern.marcel.sachs@porsche.de",
  },
  {
    id : [ "ks" ],
    name : "Kevin Stengele",
    mail : "extern.kevin.stengele1@porsche.de",
  },
  {
    id : [ "sb" ],
    name : "Sebastian Blettermann",
    mail : "extern.sebastian.blettermann@porsche.de",
  },
  ]
);

export default Users;