import styles from "../../styles/TicketBlocks.module.scss";
import FilenameGenerator from "../ticketBlocks/FilenameGenerator/FilenameGenerator.jsx";
import Configuration from "../ticketBlocks/Configuration/Configuration.jsx";
import Attachments from "../ticketBlocks/Attachments.jsx";
import Preconditions from "../ticketBlocks/Preconditions.jsx";
import ActionsObservationsExpectations from "../ticketBlocks/ActionsObservationsExpectations.jsx";
import Comments from "../ticketBlocks/Comments.jsx";
import Healing from "../ticketBlocks/Healing.jsx";
import Contacts from "../ticketBlocks/Contacts.jsx";
import {useContext} from "react";
import Settings from "../../data/global/Settings.js";
import classes from "../../styles/TicketBlocks.module.scss";
import Timestamps from "../ticketBlocks/Timestamps.jsx";
import TextAreaTicketBlock from "../ticketBlocks/TextAreaTicketBlock.jsx";
import Actions from "../ticketBlocks/Actions.jsx";
import Observations from "../ticketBlocks/Observations.jsx";
import Expectations from "../ticketBlocks/Expectations.jsx";

const TicketBlocks = props => {
  const settings = useContext( Settings );
  return (
    <section className = { styles.TicketBlocks }>
      {/*<div className = { styles.TicketBlocksBox }>*/}

      <div className = { styles.configuration } >
        <FilenameGenerator
          domain = { props.domain }
          initial = { props.initial }
          ticketName  = { props.ticketBlocksListFull[0] }
          folderName  = { props.ticketBlocksListFull[1] }
          screenName  = { props.ticketBlocksListFull[3] }
          videoName   = { props.ticketBlocksListFull[2] }
          tracesName  = { props.ticketBlocksListFull[4] }
          attachments = { props.ticketBlocksListFull[10] }
          setTicketNameWithItsParts = { props.setTicketNameWithItsParts }
          index = { settings.defaultValues.html.tabindex.ticketBlocks }
          text = "gen"
        />

        <Configuration
          domain = { props.domain }
          project = { props.project }
          data = { props.ticketBlocksListFull[5] }
          index = { settings.defaultValues.html.tabindex.ticketBlocks + 10 }
          compact
        />

        {/*<KPMStuff data = { props.ticketBlocksListFull[14] } index = {  + 30 } />*/}

        <Preconditions
          domain = { props.domain }
          data = { props.ticketBlocksListFull[6] }
          index = { settings.defaultValues.html.tabindex.ticketBlocks + 40 }
          compact
        />

      </div>

      <div className = { styles.mainContentContainer }>
        <div className = { styles.mainContent }>

          <Actions
            actions = { props.ticketBlocksListFull[7] }
            index = { settings.defaultValues.html.tabindex.ticketBlocks + 50 }
            aCompact
          />

          <Timestamps
            data = { props.ticketBlocksListFull[15] }
            index = { settings.defaultValues.html.tabindex.ticketBlocks + 63 }
            compact
          />

          <Observations
            observations = { props.ticketBlocksListFull[8] }
            index = { settings.defaultValues.html.tabindex.ticketBlocks + 52 }
            oCompact
          />

          <Comments compact data = { props.ticketBlocksListFull[12] } index = { settings.defaultValues.html.tabindex.ticketBlocks + 70 } />

          <Expectations
            expectations = { props.ticketBlocksListFull[9] }
            index = { settings.defaultValues.html.tabindex.ticketBlocks + 54 }
            eCompact
          />

          {/*<ActionsObservationsExpectations*/}
          {/*  actions      = { props.ticketBlocksListFull[7] }*/}
          {/*  observations = { props.ticketBlocksListFull[8] }*/}
          {/*  expectations = { props.ticketBlocksListFull[9] }*/}
          {/*  index = { settings.defaultValues.html.tabindex.ticketBlocks + 50 }*/}
          {/*  aCompact*/}
          {/*  oCompact*/}
          {/*  eCompact*/}
          {/*/>*/}

          <Healing  compact data = { props.ticketBlocksListFull[11] } index = { settings.defaultValues.html.tabindex.ticketBlocks + 80 } />

        </div>
      </div>

      <div className = { styles.etc } >
        <Attachments compact data = { props.ticketBlocksListFull[10] } index = { settings.defaultValues.html.tabindex.ticketBlocks + 90 } />
        <Contacts
          data   = { props.ticketBlocksListFull[13] }
          userid = { props.user }
          index  = { settings.defaultValues.html.tabindex.ticketBlocks + 100 }
          compact
        />
      </div>
    </section>
  );
}

export default TicketBlocks;