
export default props => {
  return (
    // <SimpleTextArea
    //   data = { props.data }
    //   compact = { props.compact }
    // />

    <>GS</>
  );
}