import React, {useContext, useEffect, useState} from "react";
import Settings from "../../data/global/Settings.js";
import Users from "../../data/global/Users.js";
import SimpleInput from "../common/SimpleInput.jsx";
import {copyNonEmptyAndNonDefaultValues} from "../../data/global/commonFunctions.js";
import Block from "../pageBlocks/Block.jsx";
import AtomicHeader from "../common/AtomicHeader.jsx";

const Contacts = props => {
  let selectedUser;
  const settings = useContext( Settings );
  const users = useContext( Users );

  // Labels
  const labelName = settings.defaultValues.labels.contacts.name;
  const labelOrganization = settings.defaultValues.labels.contacts.organization;
  const labelEmail = settings.defaultValues.labels.contacts.email;
  const labelPhone = settings.defaultValues.labels.contacts.phone;

  // - - - - USERS Start
  users.filter( user =>
    user.id.forEach( id => ( id === props.userid ) ? selectedUser = user : null ));
  selectedUser = ( selectedUser !== 0 ) ? selectedUser : users[0];

  const givenName = ( selectedUser.name ) ? selectedUser.name : settings.defaultValues.global.emptyValue;
  const givenOrganization = ( selectedUser.organization ) ? selectedUser.organization : settings.defaultValues.global.emptyValue;
  const givenEmail  = ( selectedUser.mail ) ? selectedUser.mail : settings.defaultValues.global.emptyValue;
  const givenPhone  = ( selectedUser.phone) ? selectedUser.phone : settings.defaultValues.global.emptyValue;

  // - - - - FE Start
  const [ contactsContent, setContactsContent ] = useState( props.data.value );
  const [ name, setName ] = useState( givenName );
  const [ organization, setOrganization ] = useState( givenOrganization );
  const [ email, setEmail ] = useState( givenEmail );
  const [ phone, setPhone ] = useState( givenPhone );

  const confContentElements = [ name, organization, email, phone ];
  const confContentElementsLabels = [ labelName, labelOrganization, labelEmail, labelPhone ];

  // if those subparts become changes => update GCCE
  useEffect(() =>
      // setContactsContent( copyNonEmptyAndNonDefaultValues( confContentElements, confContentElementsLabels )),
      setContactsContent( copyNonEmptyAndNonDefaultValues( confContentElements )),
    [ ...confContentElements ]);

  // changes in GCCE has to be given to the top
  useEffect(() => props.data.update( contactsContent ), [ contactsContent ]);

  // - - - - FE End
  const generateContentHere = () => {
    return (
      <>
        <AtomicHeader text = { props.data.name } />
        <SimpleInput
          name =   { name }
          label =  { labelName }
          value =  { name }
          update = { setName }
          index  = { props.index }
        />
        <SimpleInput
          name =   { email }
          label =  { labelEmail }
          value =  { email }
          update = { setEmail }
          index  = { props.index + 1 }
        />
        <SimpleInput
          name =   { organization }
          label =  { labelOrganization }
          value =  { organization }
          update = { setOrganization }
          index  = { props.index + 2 }
          />
        <SimpleInput
          name =   { phone }
          label =  { labelPhone }
          value =  { phone }
          update = { setPhone }
          index  = { props.index + 3 }
        />
    </>
    );
  }
  return (
    <Block compact >
      { generateContentHere() }
    </Block>
  );
}

export default Contacts;