import AppSettings from "../../../data/global/Settings.js";
import classes from "../../../styles/FilenameGenerator.module.scss";
import {useContext} from "react";

const CategoryInput = props => {
  const appSettings = useContext( AppSettings );
  const categoryInputValue = appSettings.nameGenerator.catInputValue;

  const inputChangeHandler = event => {
    event.preventDefault();
    props.functionsToPass[0]( event.target.value );
    props.functionsToPass[1]( true );
  }

  const onClickHandler = event => {
    event.preventDefault();
    event.target.select();
  }

  return (
    <input
      name = { props.name }
      tabIndex = { props.tabIndex }
      value = { props.value }
      placeholder = { `${ categoryInputValue } ${ props.tabIndex - 9 } ` }
      type = "text"
      maxLength = { +appSettings.nameGenerator.catInputLength }
      className = { classes.catName }
      onChange = { inputChangeHandler }
      onClick = { onClickHandler }
    />
  );
}

export default CategoryInput;