import ConfDefault from "./ConfDefault";
import ConfCarPlay from "./ConfCarPlay.jsx";
import ConfKbd from "./ConfKbd";
import ConfGs from "./ConfGs";
import ConfNav from "./ConfNav.jsx";

export default props => {
  // console.log( props.domain );
  // console.log( props.project );

  const postContentHere = () => {
    switch ( props.domain ) {
      case "cp"  : return <ConfCarPlay data = { props.data } project = { props.project } compact = { props.compact } index = { props.index } />
      case "aa"  : return <ConfCarPlay data = { props.data } project = { props.project } compact = { props.compact } index = { props.index } />
      case "gs"  : return <ConfGs data = { props.data } project = { props.project } compact = { props.compact } index = { props.index } />
      case "kbd" : return <ConfKbd data = { props.data } project = { props.project } compact = { props.compact } index = { props.index } />
      case "nav" : return <ConfNav data = { props.data } project = { props.project } compact = { props.compact } index = { props.index } />

      default : return <ConfDefault data = { props.data } project = { props.project } compact = { props.compact } index = { props.index } />
    }
  }
  return postContentHere();
}