import {useContext, useEffect} from "react";
import settings from "./Settings.js";
import users from "./Users.js";

const copyToClipboard = data => {
  navigator.clipboard.writeText( data ).then(function() {
    console.log('Copying to clipboard was successful!');
  }, function(err) {
    console.error('Could not copy text: ', err);
  });
}

// Autoresize for TextArea
const useAutosizeTextArea = ( textAreaRef, value ) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";

      textAreaRef.style.height = textAreaRef.scrollHeight + "px";
      const scrollHeight = textAreaRef.scrollHeight + 17;
      // const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
};

const copyNonEmptyAndNonDefaultValues = ( elems, elemLabels ) => {
  let content = "";
  const space = settings._currentValue.defaultValues.global.space;
  const lineBreak = settings._currentValue.defaultValues.global.lineBreak;
  const textareaDefaultValue = settings._currentValue.defaultValues.html.textarea.defaultValue;
  const emptyValueAlt = settings._currentValue.defaultValues.global.emptyValueAlt;

  const defaultUsername = users._currentValue[0].name;
  const defaultOrganization = users._currentValue[0].organization;
  const defaultEmail = users._currentValue[0].mail;
  const defaultPhone = users._currentValue[0].phone;

  elems.forEach( ( item, index ) => {
    // empty / def. value for "Other" has to be ignored
    if (
      Boolean( elems[ index ] ) &&
      elems[ index ] !== emptyValueAlt &&
      elems[ index ] !== textareaDefaultValue &&
      elems[ index ] !== defaultUsername &&
      elems[ index ] !== defaultOrganization &&
      elems[ index ] !== defaultEmail &&
      elems[ index ] !== defaultPhone
    ) {
      if ( index !== 0 ) content += lineBreak;

      content += ( elemLabels !== undefined )
      ? elemLabels[ index ] + space + item
      : item;
    }
  });

  // removing the last lineBreak in Textareas. Has to be improved.
  // return ( isTextarea ) ? content.slice( 0, content.length - lineBreak.length * 2 ) : content;
  return content;
};

const setValueToFunction = list => list.forEach( item => item.prop( item.value ));

const addHCP3Variables = ( data, vars, labels ) => [ [ ...data[0], ...vars ], [ ...data[1], ...labels ] ];

export {
  copyToClipboard,
  useAutosizeTextArea,
  copyNonEmptyAndNonDefaultValues,
  addHCP3Variables,
  setValueToFunction
};