import React, {useContext, useEffect, useState} from "react";
import Settings from "../../../data/global/Settings.js";
import SimpleInput from "../../common/SimpleInput.jsx";
import {addHCP3Variables, copyNonEmptyAndNonDefaultValues} from "../../../data/global/commonFunctions.js";
import TicketSection from "../../common/TicketSection.jsx";
import SimpleTextArea from "../../common/SimpleTextArea.jsx";

const ConfNav = props => {
  const settings = useContext( Settings );
  // const project = settings.defaultValues.project.id;
  const project = props.project;

  const labelTestedOn = settings.defaultValues.labels.general.testedOn;
  const labelSwVersion = settings.defaultValues.labels.general.swVersion;
  const labelConmod = settings.defaultValues.labels.general.conmod;
  // const labelLang = settings.defaultValues.labels.general.lang;
  const labelNavi = settings.defaultValues.labels.general.navi;
  const labelOther = settings.defaultValues.labels.general.other;

  // general configuration content element
  const [ confContent, setConfContent ] = useState( props.data.value );

  const [ testedOn, setTestedOn ] = useState( settings.defaultValues.versions[ project ].naviTestedOn );
  const [ swVersion, setSwVersion ] = useState( settings.defaultValues.versions[ project ].sw );
  const [ hcp5Version, setHcp5Version ] = useState( settings.defaultValues.versions[ project ].hcp5 );
  const [ conmodVersion, setConmodVersion ] = useState( settings.defaultValues.versions[ project ].conmod );
  // const [ lang, setLang ] = useState( settings.defaultValues.versions[ project ].lang );
  const [ navi, setNavi ] = useState( settings.defaultValues.versions[ project ].navi );
  const [ other, setOther ] = useState( settings.defaultValues.html.textarea.defaultValue );

  // let confContentElements = [ testedOn, swVersion, navi, lang, other ];
  // let confContentElementsLabels = [ labelTestedOn, labelSwVersion, labelNavi, labelLang, labelOther ];
  let confContentElements = [ testedOn, swVersion, navi, other ];
  let confContentElementsLabels = [ labelTestedOn, labelSwVersion, labelNavi, labelOther ];
  const labelHcp5 = settings.defaultValues.labels.hcp5;
  // HCP3 variables initialization

  if ( project === "hcp" ) [ confContentElements, confContentElementsLabels ] =
      addHCP3Variables( [ confContentElements, confContentElementsLabels ], [ hcp5Version, conmodVersion ], [ labelHcp5, labelConmod ] );

  // changes in local elements change the global "content"
  useEffect(() =>
      setConfContent( copyNonEmptyAndNonDefaultValues( confContentElements, confContentElementsLabels )),
    [ ...confContentElements ]);

  // give changes to parent element
  useEffect(() => props.data.update( confContent ), [ confContent ]);

  return (
    <TicketSection text = { props.data.name } compact = { props.compact } >

    <SimpleInput
      name =   { "testedOn" }
      label =  { labelTestedOn }
      value =  { testedOn }
      update = { setTestedOn }
      index  = { props.index }
      compact
    />

    <SimpleInput
      name =   { "swVersion" }
      label =  { labelSwVersion }
      value =  { swVersion }
      update = { setSwVersion }
      index  = { props.index }
      compact
    />

      <SimpleInput
        name =   { "navigation" }
        label =  { labelNavi }
        value =  { navi }
        update = { setNavi }
        index  = { props.index }
        compact
      />

      {/*<NaviInput*/}
      {/*  name =   { "navigation" }*/}
      {/*  label =  { labelNavi }*/}
      {/*  value =  { navi }*/}
      {/*  update = { setNavi }*/}
      {/*  index  = { props.index }*/}
      {/*/>*/}

    { ( project === "hcp" )
    ? <>
      <SimpleInput
        name =   { "hcp5" }
        label =  { labelHcp5 }
        value =  { hcp5Version }
        update = { setHcp5Version }
        index  = { props.index }
        compact
      />

      <SimpleInput
        name =   { "conmod" }
        label =  { labelConmod }
        value =  { conmodVersion }
        update = { setConmodVersion }
        index  = { props.index }
        compact
      />
    </>
    : null
  }

    {/*<SimpleInput*/}
    {/*  name =   { "lang" }*/}
    {/*  label =  { labelLang }*/}
    {/*  value =  { lang }*/}
    {/*  update = { setLang }*/}
    {/*  index  = { props.index }*/}
    {/*  compact*/}
    {/*/>*/}

    <SimpleTextArea
      name =   { "other" }
      label =  { labelOther }
      value =  { other }
      update = { setOther }
      index  = { props.index }
    />
  </TicketSection>
  )
}

export default ConfNav;