import React from "react";
import AtomicInput from "./AtomicInput.jsx";
import styles from "../../styles/PageBlocks.module.scss";
import SimpleBlock from "../pageBlocks/SimpleBlock.jsx";

const SimpleCheckbox = props => {
  return (
    <SimpleBlock compact = { props.compact } small = { props.small } tiny = { props.tiny } >
      <AtomicInput
        name = { props.name }
        value = { props.value }
        update = { props.update }
        placeholder = { props.value }
        maxLength = { props.maxlength }
        minLength = { props.minLength }
        withLabel = { props.withLabel }
        index = { props.index }
        type = "checkbox"
        disabled = { props.disabled }
        defaultChecked = { props.defaultChecked }
      />
    </SimpleBlock>
  );
}

export default SimpleCheckbox;