import React from "react";
import styles from "../../styles/PageBlocks.module.scss";
import AtomicTextArea from "./AtomicTextArea.jsx";
import SimpleBlock from "../pageBlocks/SimpleBlock.jsx";

const SimpleTextArea = props => {
  return (
    <SimpleBlock compact = { props.compact } small = { props.small } >
      { ( props.label ) ?
        <label
          className = { styles.simpleTextAreaLabel }
          htmlFor = { props.name }>
          { props.label + " : " }
        </label>
      : null }
      <AtomicTextArea
        value = { props.value }
        name = { props.name }
        update = { props.update }
        label = { props.label }
        index = { props.index }
      />
    </SimpleBlock>
  );
}

export default SimpleTextArea;