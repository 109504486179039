import styles from "../../styles/PageBlocks.module.scss";
import React, {useContext} from "react";
import Settings from "../../data/global/Settings.js";

const AtomicInput = props => {
  const settings= useContext( Settings );
  const pasteValue = item => ( props.withLabel ) ? props.label + " " + item.target.value : item.target.value;

  return ( <input
    name = { props.name }
    onChange = { event =>
      props.update(
        ( props.type !== "checkbox" && props.type !== "date" )
          ? pasteValue( event )
          : ( props.type === "checkbox" )
            ? event.target.checked
            : ( props.type === "date" ) ? item.target.value : pasteValue( event )
      )}
    placeholder = { props.value }
    maxLength = { ( props.maxLength > 0 ) ? props.maxLength : settings.defaultValues.html.input.maxLength }
    minLength = { ( props.minLength > 0 ) ? props.minLength : settings.defaultValues.html.input.minLength }
    className = { styles.atomicInput }
    tabIndex = { props.index }

    type = { props.type }
    value = { ( props.type === "Date" || props.type === "Time" ) ? props.value : undefined }
    data-date-format = { ( props.type === "Date" ) ? "DD MMMM YYYY" : undefined }

    disabled = { props.disabled !== undefined }
    checked = { props.defaultChecked }
  />
  );
}

export default AtomicInput;