import styles from "../../styles/PageBlocks.module.scss";
export default props => {
  // ( props.compact ) ? styles.simpleInputCompact : ( props.small )
  // ? styles.simpleInputSmall : styles.simpleInput }

  const className = ( props.date !== undefined || props.time !== undefined )
    ? (
      ( props.date !== undefined )
        ? ( props.compact ) ? styles.simpleDateInputCompact :
          ( props.small ) ? styles.simpleDateInputSmall :
            ( props.tiny ) ?  styles.simpleDateInputTiny
              : styles.simpleDateInput
        : ( props.compact ) ? styles.simpleTimeInputCompact :
          ( props.small ) ? styles.simpleTimeInputSmall :
            ( props.tiny ) ?  styles.simpleTimeInputTiny
              : styles.simpleDateInput
    ) : (
      ( props.compact ) ? styles.simpleInputCompact :
        ( props.small ) ? styles.simpleInputSmall :
          ( props.tiny ) ? styles.simpleInputTiny
            : styles.simpleInput
  );

  return (
    <div
      className = { className }
      index = { props.index }
    >
      { props.children }
    </div>
  );
}