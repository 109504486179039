import React from "react";
import AtomicInput from "./AtomicInput.jsx";
import styles from "../../styles/PageBlocks.module.scss";
import SimpleBlock from "../pageBlocks/SimpleBlock.jsx";

const SimpleInput = props => {
  return (
    <SimpleBlock compact = { props.compact } small = { props.small } tiny = { props.tiny } >
      { ( props.label ) ?
          <label
            className = { styles.simpleInputLabel }
            htmlFor = { props.name }>
            { props.label + " : " }
          </label>
      : null }
      <AtomicInput
        name = { props.name }
        value = { props.value }
        update = { props.update }
        placeholder = { props.value }
        maxLength = { props.maxlength }
        minLength = { props.minLength }
        withLabel = { props.withLabel }
        index = { props.index }
        type = { props.type }
        disabled = { props.disabled }
      />
    </SimpleBlock>
  );
}

export default SimpleInput;