import { copyToClipboard } from "../../../data/global/commonFunctions.js";
import AppSettings from "../../../data/global/Settings.js";
import {useContext} from "react";
import settings from "../../../data/global/Settings.js";

const CopyButtonBlock = props => {
  const settings = useContext( AppSettings );
  const lineBrake = settings.defaultValues.global.lineBreak;
  const doubleLineBrake = settings.defaultValues.global.doubleLineBreak;
  const textareaDefaultValue = settings.defaultValues.html.textarea.defaultValue;
  const emptyValue = settings.defaultValues.global.emptyValue;
  const emptyValueAlt = settings.defaultValues.global.emptyValueAlt;

  const createTextToCopy = listOfInfoBlocks => {
    let textToCopy = "";
    listOfInfoBlocks.forEach( ( blockItem, index ) => {

      if ( !blockItem.copyIfEmpty && (
            blockItem.value === textareaDefaultValue ||
            blockItem.value === emptyValue ||
            blockItem.value === emptyValueAlt
        )) return // do nothing

      else {
        textToCopy +=
        "[" + blockItem.name.toUpperCase() + "]" +
        lineBrake +
        blockItem.value;
      }

      if ( index < listOfInfoBlocks.length - 1 ) textToCopy += doubleLineBrake;
    });

    return textToCopy;
  }

  const createCodeToCopy = () => {
    return undefined;
  }

  const copyCodeToClipboard = ( event, passCode, listOfInfoBlocks ) => {
    event.preventDefault();
    const data = passCode
      ? createCodeToCopy( listOfInfoBlocks )
      : createTextToCopy( listOfInfoBlocks );
    copyToClipboard( data );
  }

    return (
    <>
      <button
        onClick = { event => { copyCodeToClipboard( event, false, props.ticketBlocksListShort )} }
        tabIndex = { props.index + 1 }
      >
        Copy text
      </button>

      {/*<button*/}
      {/*  onClick = { event => { copyCodeToClipboard( event,true, props.ticketBlocksListFull )} }*/}
      {/*tabIndex = { tabIndexHigh }*/}
      {/*>*/}
      {/*  Copy code*/}
      {/*</button>*/}
    </>
  );
}

export default CopyButtonBlock;