import {createContext} from "react";

const Projects = createContext([
    {
      id : [ "mib", "mib3", "m" ],
      name : "MIB3 TI Premium",
    },
    {
      id : [ "hcp", "hcp3", "h" ],
      name : "HCP3",
    },
  ]
);

export default Projects;