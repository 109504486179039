import {createContext} from "react";

const Domains = createContext([
    {
      id : [ "cp", "carplay", "c", "cr" ],
      name : "CarPlay",
    },
    {
      id : [ "kbd", "k" ],
      name : "Keyboard",
    },
    {
      id : [ "gs", "g" ],
      name : "Global Search",
    },
    {
      id : [ "nav", "n" ],
      name : "Navigation",
    },
  ]
);

export default Domains;