import {useContext, useEffect, useState} from "react";
import AppSettings from "../../../data/global/Settings.js";
import classes from "../../../styles/FilenameGenerator.module.scss";
import CategoryInput from "./CategoryInput.jsx";
import TicketSection from "../../common/TicketSection.jsx";
import {setValueToFunction} from "../../../data/global/commonFunctions.js";
import settings from "../../../data/global/Settings.js";

const FilenameGenerator = props => {
  const appSettings = useContext( AppSettings );
  const maxOverallKPMTitleLength = appSettings.nameGenerator.maxOverallKPMTitleLength;
  const spaceBtwCatsAndTitleWidth = appSettings.nameGenerator.spaceBtwCatsAndTitleWidth;
  const tabIndexInit = props.index;

  // STATES START
  const [ firstCategory,  setFirstCategory ]          = useState( props.ticketName.firstCategory );
  const [ secondCategory, setSecondCategory ]         = useState( props.ticketName.secondCategory );
  const [ thirdCategory,  setThirdCategory ]          = useState( props.ticketName.thirdCategory );
  const [ enteredDescription, setEnteredDescription ] = useState( props.ticketName.enteredDescription );

  const [ ticketTitle, setTicketTitle ] = useState( props.ticketName.default );
  const [ folderTitle, setFolderTitle ] = useState( props.folderName.default );
  const [ screenTitle, setScreenTitle ] = useState( props.screenName.default );
  const [ videoTitle, setVideoTitle ]   = useState( props.videoName.default );
  const [ tracesTitle, setTracesTitle ] = useState( props.tracesName.default );

  const [ useScreenshots, setUseScreenshots ] = useState(false );
  const [ useVideos, setUseVideos ] = useState(false );
  const [ useTraces, setUseTraces ] = useState(true );

  const [ notFirstRun,  setNotFirstRun ] = useState(false );

  const categories = [ firstCategory, secondCategory, thirdCategory ];

  const [ maxTitleLength, setMaxTitleLength ] = useState( maxOverallKPMTitleLength - spaceBtwCatsAndTitleWidth );
  const [ symbolsLeftForTitle, setSymbolsLeftForTitle ] = useState( maxOverallKPMTitleLength );

  const dataToClearWithValues = [
    { prop : setTicketTitle,        value : props.ticketName.default },
    { prop : setFolderTitle,        value : props.folderName.default },

    { prop : setFirstCategory,      value : props.ticketName.firstCategory },
    { prop : setSecondCategory,     value : props.ticketName.secondCategory },
    { prop : setThirdCategory,      value : props.ticketName.thirdCategory },
    { prop : setEnteredDescription, value : props.ticketName.enteredDescription },

    { prop : setScreenTitle,        value : props.screenName.default },
    { prop : setVideoTitle,         value : props.videoName.default },
    { prop : setTracesTitle,        value : props.tracesName.default },

    { prop : setNotFirstRun,        value : false },
  ];

  // STATES END

  let attachments = [];
  let attachmentsAsText = "";

  const generateNames = () => {
    const removeSpaceAsLastChars = string => {
      let numberOfSpaces = 0;
      let lastChar;

      do {
        lastChar = string.charAt( string.length - numberOfSpaces - 1 );
        numberOfSpaces++;
      } while ( lastChar === " " );

      return string.substr( 0, string.length - --numberOfSpaces );
    }

    let ticketTitle = "";
    let internalTitle = "";

    let categoriesToUse = [];

    let enteredTitleNoSpacesAtEnd = removeSpaceAsLastChars( enteredDescription );

    categories.forEach( category => ( category.length > 0 ) ?
      categoriesToUse.push( removeSpaceAsLastChars( category )) : null );

    categoriesToUse.forEach(( category, index, array)  => {
      ticketTitle += `[${ category.toUpperCase() }]`;
      internalTitle += `${ category }_`;
    });

    ticketTitle += " " + enteredTitleNoSpacesAtEnd;
    internalTitle += enteredTitleNoSpacesAtEnd;

    internalTitle = internalTitle
      .replace(/ the /g, "_")
      .replace(/ a /g, "_")
      .replace(/ an /g, "_")
      .replace(/ /g, "_")
      .replace(/\//g, "")
      .replace(/\\/g, "")
      .replace(/"/g, "")
      .replace(/'/g, "")
      .replace(/</g, "")
      .replace(/>/g, "")
      .replace(/\./g, "_")
      .replace(/\:/g, "_")
      .replace(/__/g, "_")
      .replace(/__/g, "_")
      .replace(/__/g, "_")
      .replace(/__/g, "_")
      .toLowerCase();

    // Ticket Title
    setTicketTitle(`${ ticketTitle }`);
    props.ticketName.update(`${ ticketTitle }`);

    // Folder Title
    setFolderTitle( internalTitle );

    if ( internalTitle ) {
      attachments = [];

      //// Screenshots
      setScreenTitle(`screenshots_${ internalTitle }.zip` );
      if ( useScreenshots ) attachments.push( screenTitle );

      //// Videos
      setVideoTitle( `video_${ internalTitle }.mp4` );
      if ( useVideos ) attachments.push( videoTitle );

      //// Traces
      setTracesTitle(
        `traces_${ internalTitle }.zip` );
      if ( useTraces ) attachments.push( tracesTitle );

      //// Attachments
      // make text from the array of attachments
      attachments.forEach( ( element, counter ) => {
        attachmentsAsText =
          "".concat( attachmentsAsText, `${ counter + 1   }. ${ element }`, appSettings.defaultValues.global.lineBreak );
      });

      // remove the last lineBreak
      attachmentsAsText = attachmentsAsText.substr( 0, attachmentsAsText.length - appSettings.defaultValues.global.lineBreak.length );

      // update attachments parent property
      props.attachments.update( attachmentsAsText );
    }
  }

  useEffect( () => { if ( notFirstRun ) generateNames()
    }, [
      firstCategory,
      secondCategory,
      thirdCategory,
      enteredDescription,

      useScreenshots,
      useVideos,
      useTraces,

      screenTitle,
      videoTitle,
      tracesTitle,
    ]
  );

  useEffect(() => {
    if ( notFirstRun ) {
      let symbolsUsedByCategories = 0;

      categories.forEach( category => {
        if ( category.length > 0 ) {
          symbolsUsedByCategories += category.length;
          symbolsUsedByCategories += 2; // for [] brackets
        }
      });

      symbolsUsedByCategories = ( symbolsUsedByCategories > 0 )
        ? symbolsUsedByCategories + spaceBtwCatsAndTitleWidth : symbolsUsedByCategories;

      setMaxTitleLength( maxOverallKPMTitleLength - symbolsUsedByCategories );
    }
  }, [
    firstCategory,
    secondCategory,
    thirdCategory,
  ]);

  useEffect(() => { if ( notFirstRun ) setSymbolsLeftForTitle(maxTitleLength - enteredDescription.length)
  }, [ ticketTitle ]);

  const descriptionChangeHandler = event => {
    event.preventDefault();
    setEnteredDescription( event.target.value );
    setNotFirstRun( true );
  }
  const onClickClearAllCategoriesAndDesc = event => {
    event.preventDefault();
    setValueToFunction( dataToClearWithValues );
  }

  props.setTicketNameWithItsParts( dataToClearWithValues );

  const copyOnClickCopyHandler = event => {
    event.preventDefault();
    const data = event.target.getAttribute('data');
    copyToClipboard( data );
  }

  const copyToClipboard = data => {
    navigator.clipboard.writeText( data ).then(function() {
      console.log('Copying to clipboard was successful!');
    }, function(err) {
      console.error('Could not copy text: ', err);
    });
  }

  return (
    <TicketSection vertical>

      <h1 className = { classes.ticketTitle }
          data = { ticketTitle }
          onClick = { copyOnClickCopyHandler }
      >
        { ticketTitle }
      </h1>

      <form>
        {/* enter the Ticket name */}
        <fieldset className = { classes.ticketNameInputForm }>

          {/* CAT 1 */}
          <CategoryInput
            tabIndex = { tabIndexInit }
            value = { firstCategory }
            functionsToPass = { [ setFirstCategory, setNotFirstRun ] }
          />

          {/* CAT 2 */}
          <CategoryInput
            tabIndex = { tabIndexInit + 1 }
            value = { secondCategory }
            functionsToPass = { [ setSecondCategory, setNotFirstRun ] }
          />

          {/* CAT 3 */}
          <CategoryInput
            tabIndex = { tabIndexInit + 2 }
            value = { thirdCategory }
            functionsToPass = { [ setThirdCategory, setNotFirstRun ] }
          />

          {/* KURZE BESCHREIBUNG */}
          <input
            className = { classes.kurzeBeschreibung }
            value = { enteredDescription }
            maxLength = { maxTitleLength }
            onChange = { descriptionChangeHandler }
            placeholder = { appSettings.defaultValues.ticketBlocks.shortDescription }
            tabIndex =  { tabIndexInit + 3 }
            type = "text"
          />

          {/* INFO : How many symbols left */}
          <label
            id="symbolsLeft"
            className = { classes.symbolsLeft }
          >
            { symbolsLeftForTitle }
          </label>

          {/* CLEAR ALL BUTTON */}
          <input type="button"
                 className = { classes.clearAll }
                 onClick = { onClickClearAllCategoriesAndDesc }
                 value = "Clear"
                 // tabIndex =  { tabIndexLow }
          />
        </fieldset>
      </form>

      {/*Generated Filenames*/}
      <div className = { classes.resultArea }>
        <table >
          <tbody>
          <tr className = { classes.bottomMarginHigh }>
            <td
            >Folder :</td>
            <td
              data = { folderTitle }
              onClick = { copyOnClickCopyHandler }
              colSpan = "2"
            >{ folderTitle }</td>
          </tr>

          <tr>
            <td
            >Screenshot :</td>
            <td
              data = { screenTitle }
              onClick = { copyOnClickCopyHandler }
            >{ screenTitle }</td>
            <td>
              <input
                type = "checkbox"
                checked = { useScreenshots }
                onChange = { event => setUseScreenshots( event.target.checked ) }
                tabIndex =  { tabIndexInit + 4 }
              />
            </td>
          </tr>
          <tr className={ classes.bottomMarginHigh }>
            <td
            >Video :</td>
            <td
              data = { videoTitle }
              onClick = { copyOnClickCopyHandler }
            >{ videoTitle }</td>
            <td>
              <input
                type = "checkbox"
                checked = { useVideos }
                onChange = { event => setUseVideos( event.target.checked )}
                tabIndex =  { tabIndexInit + 5 }
              />
            </td>
          </tr>
          <tr>
            <td>Traces :</td>
            <td
              data = { tracesTitle }
              onClick = { copyOnClickCopyHandler }
            >{ tracesTitle }</td>
            <td>
              <input
                type = "checkbox"
                checked = { useTraces }
                onChange = { event => setUseTraces( event.target.checked )}
                tabIndex =  { tabIndexInit + 6 }
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
</TicketSection>
  );
}

export default FilenameGenerator;