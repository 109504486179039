import TicketSection from "../common/TicketSection.jsx";
import React, {useContext, useEffect, useState} from "react";
import Settings from "../../data/global/Settings.js";
import Timestamp from "./Timestamps/Timestamp.jsx";

const Timestamps = props => {
  const settings = useContext( Settings );
  const linebreak = settings.defaultValues.global.lineBreak;
  const emptyValue = settings.defaultValues.global.emptyValue;
  const defaultTicketBlockValue = settings.defaultValues.ticketBlocks._default;

  const [ timestampsContent, setTimestampsContent ] = useState( props.data.value );

  const today = new Date();
  const todayDate = today.toISOString().split('T')[0];
  const todayTime = today.toLocaleTimeString().slice(0,5);

  const [ dateOne, setDateOne ] = useState( todayDate );
  const [ dateTwo, setDateTwo ] = useState( todayDate );

  const [ timeOne, setTimeOne ] = useState( todayTime );
  const [ timeTwo, setTimeTwo ] = useState( todayTime );

  const [ triggerOne, setTriggerOne ] = useState( emptyValue );
  const [ triggerTwo, setTriggerTwo ] = useState( emptyValue );

  let timestampOneContent = settings.defaultValues.global.emptyValue;
  let timestampTwoContent = settings.defaultValues.global.emptyValue;

  const timestampOneContentElements = [ dateOne, timeOne, triggerOne ];
  const timestampTwoContentElements = [ dateTwo, timeTwo, triggerTwo ];
  const timestampOneContentElementsSetters = [ setDateOne, setTimeOne, setTriggerOne ];
  const timestampTwoContentElementsSetters = [ setDateTwo, setTimeTwo, setTriggerTwo ];

  const [ firstCheckboxStatus, setFirstCheckboxStatus ] = useState( false );
  const [ secondCheckboxStatus, setSecondCheckboxStatus ] = useState( false );

  const [ disabledInFirstTimeStamp, setDisabledInFirstTimeStamp ] = useState( true );
  const [ disabledInSecondTimeStamp, setDisabledInSecondTimeStamp ] = useState( true );

  const conv = value => value ? undefined :value;
  const setValuesToDefault = firstTimestamp => {
    const usedVars = ( firstTimestamp ) ? timestampOneContentElementsSetters : timestampTwoContentElementsSetters;
    usedVars[0]( todayDate );
    usedVars[1]( todayTime );
    usedVars[2]( emptyValue );
  }

  // monitor events from parent element - like full reset
  useEffect(() => {
    if ( props.data.value === defaultTicketBlockValue ) setFirstCheckboxStatus( false )
  }, [ props.data.value ]);

  // give changes to parent element
  useEffect(() => {
    props.data.update( timestampsContent );
  }, [ timestampsContent ]);

  // firstCheckboxStatus update
  useEffect(() => {
    setValuesToDefault( true );
    setDisabledInFirstTimeStamp( conv( firstCheckboxStatus ) );
    if ( !firstCheckboxStatus ) setSecondCheckboxStatus( false );
  }, [ firstCheckboxStatus ]);

  // secondCheckboxStatus update
  useEffect(() => {
    setValuesToDefault( false );
    setDisabledInSecondTimeStamp( conv( secondCheckboxStatus ) );
  }, [ secondCheckboxStatus ]);

  // changes in local elements change the global "content"
  useEffect(() => {
    let content = "";
    const buildTimestampContent = contentArray => {
      const date = `${ contentArray[0].substring( 8, 10 ) }.${ contentArray[0].substring( 5, 7 ) }.${ contentArray[0].substring( 2, 4 ) }`;
      const trigger = ( contentArray[2] !== emptyValue ) ? `Trigger #${ contentArray[2] } a` : "A" ;
      return `${ trigger }t ${ contentArray[1] } on ${ date }`;
    }

    if ( firstCheckboxStatus ) {
      timestampOneContent = buildTimestampContent( timestampOneContentElements );
      content = timestampOneContent;
    }

    if ( secondCheckboxStatus ) {
      timestampTwoContent = buildTimestampContent( timestampTwoContentElements );
      content += linebreak + timestampTwoContent;
    }
    setTimestampsContent( content );
  },
[ ...timestampOneContentElements, ...timestampTwoContentElements, firstCheckboxStatus, secondCheckboxStatus ]);

  return (
    <TicketSection text = { props.data.name } compact = { props.compact } >
      <Timestamp
        firstCheckboxStatus = { firstCheckboxStatus }
        setFirstCheckboxStatus = { setFirstCheckboxStatus }
        values = { timestampOneContentElements }
        setters = { timestampOneContentElementsSetters }
        visible = { true }
        disabled = { disabledInFirstTimeStamp }
        index = { props.index }
        firstTimestamp
      />
      <Timestamp
        secondCheckboxStatus = { secondCheckboxStatus }
        setSecondCheckboxStatus = { setSecondCheckboxStatus }
        values = { timestampTwoContentElements }
        setters = { timestampTwoContentElementsSetters }
        disabledSecond = { disabledInSecondTimeStamp }
        index = { props.index }
        visible = { firstCheckboxStatus }
      />
    </TicketSection>
  );
}

export default Timestamps;