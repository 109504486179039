import styles from "../../../src/styles/PageBlocks.module.scss"
import {useContext, useRef} from "react";
import Settings from "../../data/global/Settings.js";
import {useAutosizeTextArea} from "../../data/global/commonFunctions.js";

const AtomicTextArea = props => {
  const settings= useContext( Settings );

  const linesInContent = props.value.split(/\r\n|\r|\n/).length;
  const textAreaRef = useRef(null);
  useAutosizeTextArea( textAreaRef.current, props.value );
  const rowsToSet = ( linesInContent > 1 ) ? linesInContent : linesInContent;
  // const rowsToSet = ( linesInContent > 1 ) ? linesInContent : linesInContent + 1;

  return (
    <textarea
      name = { props.name }
      value = { props.value }
      placeholder = { props.value }
      onChange = { event => props.update( event.target.value ) }
      rows = { rowsToSet }
      cols = { settings.defaultValues.html.textarea.cols }
      ref = { textAreaRef }
      className = { styles.atomicTextArea }
      tabIndex = { props.index }
    />
  );
}

export default AtomicTextArea;