import React from "react";
import styles from "../styles/TicketWriterModule.module.scss";
import Header from "../components/pageSections/Header.jsx";
import Footer from "../components/pageSections/Footer.jsx";

import {
  Outlet,
} from "react-router-dom";

export default () => {

  return (
    <>
      <Header/>
      <main className = { styles.TicketWriterModule } >
        <Outlet />
      </main>
      <Footer/>
    </>
  );
}