import TicketSection from "../../common/TicketSection.jsx";
import SimpleInput from "../../common/SimpleInput.jsx";
import SimpleTextArea from "../../common/SimpleTextArea.jsx";
import React from "react";

export default props => {
  const postContentHere = () => {
    return (
      <>
        "HCP3 :"
        "Porsche KBD :"
        "Layout : "

        "Tested on : "
        "Language : "
      </>
    );
  }

  return (
    <TicketSection text = { props.data.name } compact = { props.compact } >
    {/*<SimpleInput*/}
    {/*  name =   { "lang" }*/}
    {/*  label =  { labelLang }*/}
    {/*  value =  { lang }*/}
    {/*  update = { setLang }*/}
    {/*  compact*/}
    {/*/>*/}

    {/*<SimpleTextArea*/}
    {/*  name =   { "other" }*/}
    {/*  label =  { labelOther }*/}
    {/*  value =  { other }*/}
    {/*  update = { setOther }*/}
    {/*  compact*/}
    {/*/>*/}
    <SimpleTextArea
      name = { props.data.name }
      value = { props.data.value }
      update = { props.data.update }
    />
  </TicketSection>
  )
}