import React, {useContext, useEffect, useState} from "react";
import SimpleInput from "../../common/SimpleInput.jsx";
import Settings from "../../../data/global/Settings.js";
import PhoneEntry from "../../common/PhoneEntry.jsx";
import {addHCP3Variables, copyNonEmptyAndNonDefaultValues} from "../../../data/global/commonFunctions.js";
import AtomicHeader from "../../common/AtomicHeader.jsx";
import Block from "../../pageBlocks/Block.jsx";
import SimpleTextArea from "../../common/SimpleTextArea.jsx";

const ConfCarPlay = props => {
  const settings = useContext( Settings );
  const space = settings.defaultValues.global.space;

  const labelTestedOn = settings.defaultValues.labels.general.testedOn;
  const labelSwVersion = settings.defaultValues.labels.general.swVersion;
  const labelHcp5 = settings.defaultValues.labels.general.hcp5;
  const labelConmod = settings.defaultValues.labels.general.conmod;
  const labelIphone1 = settings.defaultValues.labels.smartIntegration.iphone;
  const labelIphone1isMaster = settings.defaultValues.labels.smartIntegration.isMaster;
  const labelIphone2isMaster = settings.defaultValues.labels.smartIntegration.isMaster;
  const labelIphone2 = settings.defaultValues.labels.smartIntegration.iphone2;
  const labelIOS1 = settings.defaultValues.labels.smartIntegration.ios;
  const labelIOS2 = settings.defaultValues.labels.smartIntegration.ios;
  const labelConnected = settings.defaultValues.labels.smartIntegration.connected;
  const labelLang = settings.defaultValues.labels.general.lang;
  const labelOther = settings.defaultValues.labels.general.other;

  const summaryPhone1Label = settings.defaultValues.labels.smartIntegration.iphone;
  const summaryPhone2Label = settings.defaultValues.labels.smartIntegration.iphone2;

  // if general project given => use default project instead
  // ??? WTF
  const project = ( props.project !== settings.defaultValues.project.id ) ? props.project : settings.defaultValues.project.default;

  // general configuration content element ( GCCE )
  const [ confContent, setConfContent ] = useState( props.data.value );

  // subparts of the GCCE
  const [ swVersion, setSwVersion ] = useState( settings.defaultValues.versions[ project ].sw );
  const [ hcp5Version, setHcp5Version ] = ( project === "hcp" ) ? useState( settings.defaultValues.versions[ project ].hcp5 ) : [];
  const [ conmodVersion, setConmodVersion ] = useState( settings.defaultValues.versions[ project ].conmod );

  const [ iphone1Version, setIphone1Version ] = useState( settings.defaultValues.versions[ project ].iphone );
  const [ iphone2Version, setIphone2Version ] = useState( settings.defaultValues.versions[ project ].iphone );
  const [ iphone1IsMaster, setIphone1IsMaster ] = useState( settings.defaultValues.versions[ project ].isMaster );
  const [ iphone2IsMaster, setIphone2IsMaster ] = useState( settings.defaultValues.versions[ project ].isMaster );
  const [ ios1Version, setIos1Version ] = useState( settings.defaultValues.versions[ project ].ios );
  const [ ios2Version, setIos2Version ] = useState( settings.defaultValues.versions[ project ].ios );
  const [ summaryPhone1, setSummaryPhone1 ] = useState( settings.defaultValues.versions[ project ].summary1 );
  const [ summaryPhone2, setSummaryPhone2 ] = useState( settings.defaultValues.versions[ project ].summary2 );

  const [ testedOn, setTestedOn ] = useState( settings.defaultValues.versions[ project ].testedOn );
  const [ lang, setLang ] = useState( settings.defaultValues.versions[ project ].lang );
  const [ connected, setConnected ] = useState( settings.defaultValues.versions[ project ].connected );
  const [ other, setOther ] = useState( settings.defaultValues.html.textarea.defaultValue );

  // subparts to react on changes in
        let confContentElements = [ testedOn, connected, swVersion, conmodVersion, iphone1Version, iphone2Version, iphone1IsMaster, iphone2IsMaster, ios1Version, ios2Version, lang, other ];
  let confContentElementsLabels = [ labelTestedOn, labelConnected, labelSwVersion, labelConmod, labelIphone1, labelIphone2, labelIphone1isMaster, labelIphone2isMaster, labelIOS1, labelIOS2, labelLang, labelOther, ];

        let confContentElementsToGiveToParent = [ testedOn, connected, swVersion, conmodVersion, summaryPhone1, summaryPhone2, lang, other ];
  let confContentElementsToGiveToParentLabels = [ labelTestedOn, labelConnected, labelSwVersion, labelConmod, summaryPhone1Label, summaryPhone2Label, labelLang, labelOther ];

  if ( project === "hcp" ) [ confContentElements, confContentElementsLabels ] =
    addHCP3Variables( [ confContentElements, confContentElementsLabels ], [ hcp5Version ], [ labelHcp5 ] );

  if ( project === "hcp" ) [ confContentElementsToGiveToParent, confContentElementsToGiveToParentLabels ] =
    addHCP3Variables( [ confContentElementsToGiveToParent, confContentElementsToGiveToParentLabels ], [ hcp5Version ], [ labelHcp5 ] );

  // const confContentElements = ( project === "hcp" )
  //   ? [ testedOn, connected, swVersion, hcp5Version, conmodVersion, iphone1Version, iphone2Version, iphone1IsMaster, iphone2IsMaster, ios1Version, ios2Version, lang, other  ]
  //   : [ testedOn, connected, swVersion, conmodVersion, iphone1Version, iphone2Version, ios1Version, ios2Version, iphone1IsMaster, iphone2IsMaster, lang, other  ];

  // const confContentElementsLabels = ( project === "hcp" )
  //   ? [ labelTestedOn, labelConnected, labelSwVersion, labelHcp5, labelConmod, labelIphone, labelIOS,  labelLang, labelOther,  ]
  //   : [ labelTestedOn, labelConnected, labelSwVersion, labelConmod, labelIphone, labelIOS, labelLang, labelOther ];

  // if those subparts become changes => update GCCE
  useEffect(() =>
    setConfContent( copyNonEmptyAndNonDefaultValues( confContentElementsToGiveToParent, confContentElementsToGiveToParentLabels )),
    [ ...confContentElementsToGiveToParent ]);

  // changes in GCCE has to be given to the top
  useEffect(() => props.data.update( confContent ), [ confContent ]);

  useEffect(() => {
    ( iphone1IsMaster ) ? setIphone2IsMaster( false ) : null;
    ( iphone2IsMaster ) ? setIphone1IsMaster( false ) : null;
  }, [ iphone1IsMaster, iphone2IsMaster ]);

  useEffect( () => {
    // let content = `iPhone 1${ space }${ iphone1Version } with ${ ios1Version } `;
    let content = `${ space }${ iphone1Version } with ${ ios1Version } [ CP SOURCE ] [ WIRED ]`;
    setSummaryPhone1( content );

  }, [ iphone1Version, iphone1IsMaster, ios1Version ] );

  useEffect( () => {
    let content = `iPhone 2${ space }${ iphone2Version } with ${ ios2Version } `;
    setSummaryPhone2( content );
  }, [ iphone2Version, iphone2IsMaster, ios2Version ] );

  const generateContentHere = () => {
    return (
      <>
        <AtomicHeader text = { props.data.name } />

        <SimpleInput
          name =   "testedOn"
          label =  { labelTestedOn }
          value =  { testedOn }
          update = { setTestedOn }
          compact
        />

        <SimpleInput
          name = "connected"
          label = { labelConnected }
          value = { connected }
          update = { setConnected }
          compact
        />

        <SimpleInput
          name = "swVersion"
          label = { labelSwVersion }
          value = { swVersion }
          update = { setSwVersion }
          compact
        />

        { ( project === "hcp" )
          ? <SimpleInput
            name = "hcp5"
            label =  { labelHcp5 }
            value = { hcp5Version }
            update = { setHcp5Version }
            compact
          />
          : null
        }

        <SimpleInput
          name = "conmod"
          label = { labelConmod }
          value = { conmodVersion }
          update = { setConmodVersion }
          compact
        />

        <SimpleInput
          name = "lang"
          label = { labelLang }
          value = { lang }
          update = { setLang }
          compact
        />

        {/*<PhoneEntry*/}
        {/*  valuePhoneVersion = { iphone1Version }*/}
        {/*  // valuePhoneIsMaster = { iphone1IsMaster }*/}
        {/*  valuePhoneIsMaster = { true }*/}
        {/*  valueOSVersion = { ios1Version }*/}
        {/*  valuePhoneEntrySummary = { summaryPhone1 }*/}
        {/*  setPhoneVersion = { setIphone1Version }*/}
        {/*  setPhoneIsMaster = { setIphone1IsMaster }*/}
        {/*  setPhoneOSVersion = { setIos1Version }*/}
        {/*  iphone*/}
        {/*/>*/}

        {/*<PhoneEntry*/}
        {/*  valuePhoneVersion = { iphone2Version }*/}
        {/*  valuePhoneIsMaster = { iphone2IsMaster }*/}
        {/*  valueOSVersion = { ios2Version }*/}
        {/*  valuePhoneEntrySummary = { summaryPhone2 }*/}
        {/*  setPhoneVersion = { setIphone2Version }*/}
        {/*  setPhoneIsMaster = { setIphone2IsMaster }*/}
        {/*  setPhoneOSVersion = { setIos2Version }*/}
        {/*  iphone*/}
        {/*/>*/}

        <SimpleTextArea
          name = "other"
          label = { labelOther }
          value = { other }
          update = { setOther }
        />
      </>
    );
  }

  return (
    <Block compact >
      { generateContentHere() }
    </Block>
  );
}

export default ConfCarPlay;