import SimpleTextArea from "../common/SimpleTextArea.jsx";
import TicketSection from "../common/TicketSection.jsx";
import React from "react";
export default props => {
  const name   = props.data.name;
  const value  = props.data.value;
  const update = props.data.update;

  return (
    <TicketSection text = { name } compact = { props.compact } >
      <SimpleTextArea
        name = { name }
        value = { value }
        update = { update }
        index  = { props.index }
      />
    </TicketSection>
  );
}

